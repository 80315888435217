<template>
  <spinner v-if="loading"/>
  <b-card v-else>
    <!-- form -->
    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col md="3" class="d-flex flex-column">
            <h2 style="border-bottom: 1px solid #404656; margin-bottom: 1rem; padding-bottom: 0.5rem">Selected Plan</h2>
            <pricing-card v-if="plan" class="border" :plan="plan"/>
          </b-col>
          <b-col md="9">
            <div class="d-flex flex-column">
              <h2 style="border-bottom: 1px solid #404656; margin-bottom: 1rem; padding-bottom: 0.5rem">Invoice
                Form</h2>
              <b-col xs="12" sm="12" md="6">
                <!-- Organization name -->
                <b-form-group
                    :label="$t('fields.organization.name')"
                    label-for="register-name"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                  >
                    <b-form-input
                        id="register-name"
                        v-model="form.name"
                        name="name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <!-- Organization email -->
                <b-form-group :label="$t('fields.organization.email')" label-for="email">
                  <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required"
                  >
                    <b-form-input
                        id="email"
                        v-model="form.email"
                        name="email"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <!-- Organization phone -->
                <b-form-group :label="$t('fields.phone')" label-for="phone">
                  <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="required"
                  >
                    <phone-number v-model="form.phone"/>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col xs="12" sm="12" md="6"></b-col>
              <b-col cols="12" class="mx-0 px-0">
                <address-form v-model="form.address"/>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col sm="12" md="4" class="ml-auto">
            <b-button
                type="submit"
                block
                variant="success"
                :disabled="processing"
                @click.prevent="checkout"
            >
              <b-spinner small v-if="processing"/>
              <span class="sr-only" v-if="processing"> {{ $t("general.loading") }}</span>
              <span v-else>Checkout</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from "@validations";
import {update, show} from "@/api/organizations.api";
import AvatarUploader from "@/components/Person/AvatarUploader.vue";
import AddressForm from "@/components/AddressForm.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import {select, showPlan} from "@/api/subscriptions.api";
import PricingCard from '@/views/admin/Pricing/Card.vue';
import Spinner from "@/components/Spinner.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "InvoiceForm",
  components: {
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
    AvatarUploader,
    AddressForm,
    PhoneNumber,
    PricingCard,
    Spinner,
  },
  data() {
    return {
      loading: true,
      processing: false,
      form: {
        name: "",
        email: "",
        phone: "",
        address: {
          country: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
        },
      },
      plan: undefined,
      required,
      email,
    };
  },
  computed: {
    organizationId() {
      return this.$auth.user()?.organization?.id;
    },
    planId() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.fetchPlan();
      await this.fetchOrganization();
      this.loading = false;
    },
    async fetchOrganization() {
      const {data} = await show(this.organizationId);

      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] != "object") {
          this.form[key] = data[key];
        } else {
          Object.keys(this.form[key]).forEach((subKey) => {
            this.form[key][subKey] = data[key] ? data[key][subKey] ?? "" : "";
          });
        }
      });
    },
    fetchPlan() {
      return new Promise(async (resolve, reject) => {
        const {data, error} = await showPlan(this.planId);
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Plan not found',
              icon: 'XIcon',
              variant: 'danger'
            },
          });
          await this.$router.push({name: 'pricing'});
          return reject();
        }
        this.plan = data;
        resolve();
      });
    },
    checkout() {
      this.processing = true;
      this.validateForm()
          .then(async () => {
            await this.redirect();
          })
          .catch(() => {})
          .finally(() => {
            this.processing = false;
          });
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((success) => {
          if (!success) return reject();
          this.persist()
              .then(() => resolve())
              .catch(() => reject());
        });
      });
    },
    persist() {
      return new Promise(async (resolve, reject) => {
        const formData = new FormData();

        Object.keys(this.form).forEach((key) => {
          if (typeof this.form[key] != "object") {
            formData.append(key, this.form[key]);
          } else {
            Object.keys(this.form[key]).forEach((subKey) => {
              formData.append(`${key}[${subKey}]`, this.form[key][subKey]);
            });
          }
        });

        const result = await update(this.organizationId, formData);

        if (result.error) {
          this.$refs.form.setErrors(result.error);
          return reject();
        }

        resolve();
      });
    },
    redirect() {
      return new Promise(async (resolve) => {
        const {data, error} = await select({stripe_price_id: this.planId});

        if (data) {
          if (data.redirect_url) {
            this.$toast({
              component: ToastificationContent,
              props: data.message,
            });

            setTimeout(() => {
              window.location.href = data.redirect_url;
              resolve();
            }, 3000);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: data.message,
            });
            await this.$router.push({name: 'success'});
            resolve();
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "subscription.unexpected-error-during-redirect",
              icon: "XIcon",
              variant: "danger",
            },
          });
          resolve();
        }
      })
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
